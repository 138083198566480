import React from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { mobile } from "clutch/src/Style/style.mjs";

import { appURLs } from "@/app/app-urls.mjs";
import { SplashContainer } from "@/shared/ComingSoonSplash.style.jsx";
import ContentContainer from "@/shared/ContentContainer.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";

const overlayFeatureCss = () => css`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--sp-4);

  ${mobile} {
    display: flex;
    flex-direction: column;
  }
`;

const TITLE = ["overlays:meta.fortnite.title", "Fortnite Overlays"];
const DESCRIPTION = [
  "overlays:meta.fortnite.description",
  "Elevate your Fortnite experience with our exclusive collection of overlays, designed to enhance your gameplay with real time stats and insights.",
];

const FEATURES = [
  {
    title: [
      "overlays:fortnite.feature.teamLoadouts.title",
      "Team Loadouts",
    ] satisfies Translation,
    description: [
      "overlays:fortnite.feature.teamLoadouts.description",
      "View your teammates’ loadouts in real time to enhance in-game communication and strategy during those high-pressure moments.",
    ] satisfies Translation,
  },
  {
    title: [
      "overlays:fortnite.feature.realTimeStats.title",
      "Real-Time Stats",
    ] satisfies Translation,
    description: [
      "overlays:fortnite.feature.realTimeStats.description",
      "See you and your teammates’ damage, accuracy, eliminations, headshot percentage, and more in real time.",
    ] satisfies Translation,
  },
  {
    title: [
      "overlays:fortnite.feature.loadoutSuggestions.title",
      "Loadout Suggestions",
    ] satisfies Translation,
    description: [
      "overlays:fortnite.feature.loadoutSuggestions.description",
      "Get instant feedback on your loadouts, including suggestions for better gear and effective meta builds.",
    ] satisfies Translation,
  },
];

function Overlays() {
  const { t } = useTranslation();
  return (
    <PageContainer>
      <PageHeader title={TITLE} />
      <ContentContainer>
        <section className={SplashContainer()}>
          <figure className="hero-image">
            <img
              src={`${appURLs.CDN}/blitz/ui/img/homepage/fortnite/overlay-hero.webp`}
              alt="Fortnite Overlays"
              width="1016"
              height="500"
            />
          </figure>
          <div className="meta">
            <h2 className="type-h5">{t("common:features", "Features")}</h2>
            <div className={overlayFeatureCss()}>
              {FEATURES.map((feature, idx) => (
                <div className="flex column gap-3" key={idx}>
                  <h3 className="type-h6">{t(...feature.title)}</h3>
                  <p className="type-body2">{t(...feature.description)}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      </ContentContainer>
    </PageContainer>
  );
}

export function meta() {
  return {
    title: TITLE,
    description: DESCRIPTION,
    subtitle: true,
  };
}

export default Overlays;
